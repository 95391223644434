Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.myRoleApiEndPoint = "bx_block_custom_forms/all_roles";
exports.MedicalJourneyApiEndPoint = "bx_block_custom_forms/all_present_stages";
exports.learningVerticalApiEndPoint = "/bx_block_custom_forms/all_verticals";
exports.MyImmediateGoalsApiEndPoint = "/bx_block_custom_forms/all_immediate_goals";
exports.instituteListMethod = "GET";
exports.instituteListAPiEndPoint = "bx_block_profile_bio/search_institutes";
exports.createFormAPiEndPoint = "bx_block_custom_forms/custom_forms";
exports.token="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6OCwiZXhwIjoxNjg2MzE3NzU3LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.qtajs3wt_9FozBlJjL8iOYodM4KuZ0dVrsQbMYmH9798DOv21rRFy8FMgMlDzKDaKg7oZryerKM8ihX1x59fog";
exports.formAPiMethod = "POST";
exports.updateFormAPiMethod="PUT";
exports.fetchFormDataMethod="GET";
exports.formApiContentType = "multipart/form-data";
exports.errorPasswordNotValid = "Not valid.";
exports.labelHeader = " Custom Form ";
exports.btnTxtSubmit = "Submit";
exports.labelOr = "OR";
exports.placeHolderFirstName = "First Name";
exports.placeHolderLastName = "Last Name";
exports.placeHolderPhoneNo = "Phone Number";
exports.placeHolderOrg = "Organization";
exports.placeHolderTeamName = "Team Name";
exports.placeHolderEmail = "Email";
exports.placeHolderAddress = "Address";
exports.placeHolderCountry = "Country";
exports.placeHolderState = "State";
exports.placeHolderCity="City";

exports.errorEmptyFields = "All fields are required.";
exports.errorEmailNotValid = "Email not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Submit";

exports.emailRegex=/[a-zA-Z0-9#$%&\*\+-/=\?\_`|~]*[a-zA-Z0-9#$%&\*\+/=\?\_`|~]@/;

exports.myRoleHeading="My role:";
exports.myRoleInfoText="What's my position?"
exports.learningVerticalHeading = "My vertical:";
exports.learningVerticalInfoText = "What's my focus?";
exports.medicalJourneyHeading = "My healthcare journey:";
exports.medicalJourneyInfoText = "What's my present stage?";
exports.institutionHeading = "My institution:";
exports.institutionInfoText = "Where am I studying or working?";
exports.otherInstitutionPlaceholder = "Enter your institution name here";
exports.mandatoryField = "Field is mandatory";
exports.invalidInput = "Please enter a valid institution";
exports.immediateGoalsHeading = "My immediate goals: ";
exports.immediateGoalsInfoText = "What do I aspire to achieve in the next 1-2 years?";
exports.futureGoalsHeading = "My future goals: ";
exports.futureGoalsInfoText = "Where will I be in over 2 years?";
exports.futureGoalsPlaceholder = "Describe your long-term goals for the next 2+ years here...";
exports.futureGoalsPlaceholder = "Describe your long-term goals for the next 2+ years here...";
exports.heading1 = "Congratulations,";
exports.heading3 = "Joshua!";
exports.infoTextHeading = "Thanks for setting up your profile, you’re all set!";
exports.infoText = "Your preferences are key to creating a personalized and engaging educational experience on MedEd CLOUD.";
exports.btnText = "Jump to My Dashboard";
exports.loaderInfoText = "Personalizing your learning experience...";
exports.loaderFact = "Your heart beats around 100,000 times a day, each one a reminder of life's constant motion, just like the evolving world of medicine.";
exports.requestError = "An error occurred, please try again later";

exports.getMethod = "GET";
exports.postMethod = "POST";
exports.contentType = "application/json";
exports.customformContentType = "multipart/form-data"
exports.customformEndpoint = "bx_block_custom_forms/custom_forms";
exports.institutionsEndpoint = "bx_block_profile_bio/institutes";
// Customizable Area End