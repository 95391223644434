  // Customizable Area Start
  import React from "react";
  import {

  } from "@material-ui/core";

  import LoginComp from "../../signuplogin/src/LoginComp.web";
  import './styles/customForm.css';

  import CustomFormController, {
    Props
  } from "./CustomFormController.web";
  import ProgressBar from "./ProgressBar.web";

  export default class MyRole extends CustomFormController {
    constructor(props: Props) {
      super(props);

    }

    render() {
      return (
        <LoginComp
          navigation={undefined}
          id={"role-data"}
          setup={true}
          onhover={this.handleIconHover}
          onleave={this.handleIconLeave}
          showInfo={this.state.showInfo}
          bgImgColor={'linear-gradient(180deg, rgba(85, 145, 245, 0) 0%, rgba(45, 0, 247, 0.35) 50%, rgba(85, 145, 245, 0.5) 100%)'}
          heading={this.myRoleHeading}
          infoText1={this.myRoleInfoText}
          infoTextStyle={"desc-text"}
          buttonText={'Next'}
          btnDisabled={this.state.roleBtn}
          onclick={this.handleRoleClick}
          customForm={
            <ProgressBar
              navigation={undefined}
              id={""}
              role={this.state.roleStep}
              vertical={false}
              journey={false}
              institution={false}
              immediateGoal={false}
              futureGoal={false}
              step1={false}
              step2={false}
              step3={false}
              step4={false}
              step5={false}
              step6={false}
            />
          }
          formContent={
            <div className="roles-container" data-testid="mdJomdJourneyContainer">

              {this.state.myRole.map((role: any, index: number) => {
                const isSelected = this.state.selectedRole === role.name;

                return (
                  <div
                    key={role.id}
                    className={`roles-wrapper ${isSelected ? 'roles-selected-wrapper' : ''}`}
                    data-testid={`${role.name}-wrapper`}
                  >
                    <span
                      className={`roles-icon ${isSelected ? 'roles-selected-icon' : ''}`}
                      data-testid={`${role.name}-iconWrapper`}
                    >
                      <img
                        src={role.image?.url || 'I'}
                        alt={`${role.name}-icon`}
                        data-testid={`${role.name}-icon`}
                      />
                    </span>
                    <label
                      htmlFor={`roles-${role.id}`}
                      className={`roles-label ${isSelected ? 'roles-selected-label' : ''}`}
                      data-testid={`${role.name}-label`}
                    >
                      {role.name}
                      <input
                        type="radio"
                        id={`roles-${role.id}`}
                        name="roles"
                        value={role.name}
                        className="radioBtn"
                        onChange={this.handleMyRole}
                        checked={isSelected}
                        data-testid={`${role.name}-roles`}
                      />
                    </label>
                  </div>
                )
              })}
            </div>
          }
        />
      );
    }
  }





  // Customizable Area End