export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const neurology = require("../assets/neurology.png");
export const note_stack = require("../assets/note_stack.png");
export const Horn = require("../assets/Horn.png");
export const calendar_month = require("../assets/calendar_month.png");
export const graph = require("../assets/bar_chart_4_bars.png");
export const Bulb = require("../assets/skip_emoji_objects.png");
export const Home = require("../assets/skip_storefront.png");
export const Spacks = require("../assets/skip_Union.png");
export const medEdsmall = require("../assets/medED.png");
export const card1 = require("../assets/Card1.png");
export const card2 = require("../assets/Card2.png");
export const card3 = require("../assets/Card3.png");
export const filter = require("../assets/filter.png");
export const skip_icon1 = require("../assets/skip_icon(1).png");
export const skip_icon2 = require("../assets/skip_icon(2).png");
export const skip_icon3 = require("../assets/skip_icon(3).png");
export const skip_icon4 = require("../assets/skip_icon(4).png");
export const skip_icon5 = require("../assets/skip_icon(5).png");
export const skip_icon6 = require("../assets/skip_icon(6).png");
export const skip_icon7 = require("../assets/skip_icon(7).png");
export const skip_icon = require("../assets/skip_icon(0).png");
export const card4 = require("../assets/card4.jpeg");
export const card5 = require("../assets/card5.jpeg");
export const card6 = require("../assets/card6.jpeg");
export const question = require("../assets/question.png");
export const Radialbackground = require("../assets/Radialbackground.png");
export const summarize = require("../assets/summarize.png");
export const organ = require("../assets/organ.jpg");
export const exam = require("../assets/skip_exam.png");
export const prev = require("../assets/arrow_right.svg");
export const next = require("../assets/skip_arrow_right.svg");
export const firstBannerMed = require("../assets/image_banner.svg");
export const secondBannerMed = require("../assets/image_banner(1).svg");
export const thirdBannerMed = require("../assets/image_banner(2).svg");
export const forthBannerMed = require("../assets/image_banner(3).svg")
export const courseMed = require("../assets/image_course.svg")
export const AtoZ = require("../assets/skip_AZ.svg");
export const ZtoA = require("../assets/skip_ZA.svg");
export const low = require("../assets/low.svg");
export const high = require("../assets/high.svg")
export const calender2 = require("../assets/calender2.png");
export const tasknote = require("../assets/tasknote.png");


