import React from 'react'

function ChartHours() {
    return (
        <div style={{position:"relative",width:'100%',
        height: '110px'}}>
        
            <div className='hourchart' style={{ display: "flex",position:"absolute", justifyContent: "space-around", flexDirection: 'column', width:"100%", height: '100px'}}>
                {['8h', '6h', '4h', '2h'].map((day: string) => {
                    return <span data-test-id={`hours-${day}`} style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '12px', fontWeight: 400, width: '100%',opacity:'30%' }}>{day}</span>
                })}
            </div>
            <div className='mr-10' style={{ display: "flex",position:"absolute", justifyContent: "space-evenly", alignItems: "flex-end", width: '100%', bottom: '0' }}>
                {[
                    {width:"25px",day:"Mo"},
                    {width:"50px",day:"Tu"},
                    {width:"37px",day:"We"},
                    {width:"67px",day:"Th"},
                    {width:"0px",day:"Fr"},
                    {width:"0px",day:"Sa"},
                    {width:"0px",day:"Su"}
                ].map((d: any, key: number) => {
                    return <LineUp data-test-id={`day-${key}`} key={key} height={d.width} day={d.day} backgroundColor="rgba(114, 239, 221, 1)"/>
                })}
            </div>
   

        </div>

    )
}
interface LineUpProps {
    width?: string,
    height: string,
    backgroundColor?: string,
    key: number,
    day?: string

}


export function LineUp({ width = "20px", height, backgroundColor = "red", key,day }: LineUpProps) {
    return (
        <div style={{display:'flex',flexDirection:"column"}}>
        <div className="mr-10" key={key} style={{
            width,
            height,
            backgroundColor,
            borderRadius: "4px",
           
        }}></div>
        <div className="graphtext" style={{fontSize:'12px', marginTop: '4px',color:'rgb(211, 211, 211)', opacity: '.3', paddingTop: '10px', }}>{day}</div>
        </div>
    )
}


export default ChartHours