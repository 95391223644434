// Customizable Area Start
import React from "react";
import // Customizable Area Start
// Customizable Area End
"@material-ui/core";

// Customizable Area Start
import LoginComp from "../../signuplogin/src/LoginComp.web";
import "./styles/customForm.css";

import CustomFormController, { Props } from "./CustomFormController.web";
import ProgressBar from "./ProgressBar.web";
// Customizable Area End

export default class MyFutureGoals extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const maxLength = 500;
    const remainingChar = maxLength - this.state.futureGoal.length;
    return (
      // Customizable Area Start
      <LoginComp
        navigation={undefined}
        id={""}
        setup={true}
        onhover={this.handleIconHover}
        onleave={this.handleIconLeave}
        showInfo={this.state.showInfo}
        bgImgColor={
          "linear-gradient(180deg, rgba(85, 145, 245, 0) 0%, rgba(247, 37, 133, 0.25) 50%, rgba(85, 145, 245, 0.5) 100%)"
        }
        heading={this.futureGoalsHeading}
        infoText1={this.futureGoalsInfoText}
        infoTextStyle={"desc-text"}
        backBtn={true} 
        goBack={this.goBack}
        buttonText={"Finish"}
        btnDisabled={this.state.futureGoalBtn}
        onclick={this.handleFutureGoal}
        warning={this.state.warning}
        customForm={
          <ProgressBar
            navigation={undefined}
            id={""}
            role={true}
            vertical={true}
            journey={true}
            institution={true}
            immediateGoal={true}
            futureGoal={this.state.futureGoalStep}
            step1={true}
            step2={true}
            step3={true}
            step4={true}
            step5={true}
            step6={false}
          />
        }
        formContent={
          <div className="goal-container" data-testid="goal-container">
            <div className="textarea-wrapper">
              <label htmlFor="futureGoal">
                <textarea
                  id="futureGoal"
                  name="futureGoal"
                  placeholder={this.futureGoalsPlaceholder}
                  rows={5}
                  cols={30}
                  maxLength={maxLength}
                  value={this.state.futureGoal}
                  className="textarea"
                  onChange={this.handleFutureGoalChange}
                  data-testid="future-goal"
                ></textarea>
              </label>
              {this.state.showCounter && <div className="text-counter">
                <p data-testid="text-counter">{`${remainingChar}/${maxLength}`}</p>
              </div>}
            </div>
          </div>
        }
      />
      // Customizable Area End
    );
  }
}
// Customizable Area End
