export const checkboxMec1 = require("../assets/checkbox-mec-1.svg");
export const checkboxMec2 = require("../assets/checkbox-mec-2.svg");
export const checkboxMec = require("../assets/checkbox-mec.svg");
export const groupCurrentTime = require("../assets/group-current-time.svg");
export const groupLogo = require("../assets/group-logo.svg");
export const groupOptions2x = require("../assets/group-options@2x.png");
export const groupTaskOptions = require("../assets/group-task-options.svg");
export const groupHighTasksPriority = require("../assets/group-high-tasks-priority.svg");
export const groupMidTasksPriority = require("../assets/group-mid-tasks-priority.svg");
export const groupLowTasksPriority = require("../assets/group-low-tasks-priority.svg");
export const groupSwitcher1 = require("../assets/group-switcher-1.svg");
export const groupSwitcher = require("../assets/group-switcher.svg");
export const image2 = require("../assets/image-2.svg");
export const image3 = require("../assets/image-3.svg");
export const imageChevronLeftWhite = require("../assets/image-chevron-left-white.svg");
export const imageChevronLeft = require("../assets/image-chevron-left.svg");
export const imageChevronRightWhite = require("../assets/image-chevron-right-white.svg");
export const imageChevronRight = require("../assets/image-chevron-right.svg");
export const imageX2x = require("../assets/image-x@2x.png");
export const skipArrowDown = require("../assets/skip-arrow-down.svg");
export const skipArrowRight = require("../assets/skip-arrow-right.svg");
export const skipBarChart4Bars = require("../assets/skip-bar-chart-4-bars.svg");
export const skipCalendarMonth = require("../assets/skip-calendar-month.svg");
export const skipExpandMore1 = require("../assets/skip-expand-more-1.svg");
export const skipExpandMore = require("../assets/skip-expand-more.svg");
export const skipLecture = require("../assets/skip-lecture.svg");
export const skipMask1_2x = require("../assets/skip-mask-1@2x.png");
export const skipMask2_2x = require("../assets/skip-mask-2@2x.png");
export const skipMask_2x = require("../assets/skip-mask@2x.png");
export const skipMeeting = require("../assets/skip-meeting.svg");
export const skipMoreVert = require("../assets/skip-more-vert.svg");
export const skipNewLogo = require("../assets/skip-newlogo.svg");
export const skipNoteStack = require("../assets/skip-note-stack.svg");
export const skipSocial1 = require("../assets/skip-social-1.svg");
export const skipSocial2_2x = require("../assets/skip-social-2@2x.png");
export const skipSocial3_2x = require("../assets/skip-social-3@2x.png");
export const skipSocial = require("../assets/skip-social.svg");
export const skipSubstract = require("../assets/skip-substract.svg");
export const skipTask = require("../assets/skip-task.svg");
export const skipUnion1 = require("../assets/skip-union-1.svg");
export const skipUnion2 = require("../assets/skip-union-2.svg");
export const skipUnion3 = require("../assets/skip-union-3.svg");
export const skipUnion = require("../assets/skip-union.svg");
export const skipVector = require("../assets/skip-vector.svg");
export const skipWordmark = require("../assets/skip-wordmark.svg");
export const skipWorld = require("../assets/skip-world.svg");
export const viewVector1 = require("../assets/view-vector-1.svg");
export const viewVector2 = require("../assets/view-vector-2.svg");
export const viewVector = require("../assets/view-vector.svg");
export const skipLocationLink = require("../assets/skip-location-link.svg");
export const skipLocationOn = require("../assets/skip-location-on.svg");
export const groupPriority = require("../assets/group-priority.svg");
export const skipCalendar = require("../assets/skip-calendar.svg");
export const skipCircle = require("../assets/skip-circle.svg");
export const skipTaskSummarize = require("../assets/skip-task-summarize.svg");
export const tasktitle = require("../assets/skip_forum.png");
export const taskMemo = require("../assets/skip_meeting.png");
export const taskTime = require("../assets/skip_meeting (1).png");

export const meetingtitle = require("../assets/skip_forum (1).png");
export const meetingMemo = require("../assets/skip_meeting (2).png");
export const meetingTime = require("../assets/skip_location_on.png");

export const lecttitle = require("../assets/skip_forum (2).png");
export const lectMemo = require("../assets/skip_meeting (3).png");
export const lectTime = require("../assets/skip_location_on.png");

export const lectLocation = require("../assets/skip_location_on2.svg");
export const lectMeet = require("../assets/skip-location-link-lecture.svg");
